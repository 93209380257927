import React from "react"
import { useAuth } from "react-oidc-context"
import Loading from "../components/Loading"

export default function SigninRedirect() {
  const auth = useAuth()

  React.useEffect(() => {
    void auth.signinRedirect().catch(e => {
      console.error(e)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Loading text="Redirecting you to sign in..." />
}
