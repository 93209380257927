import React from "react"
import { useAuth } from "react-oidc-context"
import { Redirect } from "react-router-dom"
import Loading from "../components/Loading"
import ErrorLoader from "../components/ErrorLoader"
import {backendEnv} from "../config/config";

const API_KEY = process.env.REACT_APP_LIMBIC_ACCESS_BOT_API_KEY || "VITALITY"
const API_URL = {
    development: "https://sit.apis.vitality.co.uk/health/experience/mental-health/v1/mental-health",
    staging: "https://pre.apis.vitality.co.uk/health/experience/mental-health/v1/mental-health",
    production: "https://apis.vitality.co.uk/health/experience/mental-health/v1/mental-health"
}[backendEnv]

interface WindowWithLimbicNameSpace extends Window {
  LimbicChatbot: {
    onStart?(initFn: (config: any) => void): void
    init?: (setupConfig: any) => void
    initialized: boolean
  },
}

declare var window: WindowWithLimbicNameSpace

export default function Callback() {
  const [isFetching, setIsFetching] = React.useState(true)
  const [error, setError] = React.useState<string>()
  const auth = useAuth()
  // @ts-ignore
  window.auth = auth
  const token = auth.user?.access_token

  React.useEffect(() => {
    const callAPI = async () => {
      if (auth.user && token) {
       try {
         setError(undefined)
         const headers = { Authorization: `Bearer ${token}` }
         const res = await fetch(API_URL, { headers })
         const data = await res.json()

         if (data.error || res.status !== 200) throw new Error(JSON.stringify(data.error))
         localStorage.setItem("data", JSON.stringify(data))
       } catch (e) {
         console.error(e)
         auth.removeUser().catch(console.error)
         setError("Oops! Something went wrong fetching your details. Please try again later")
       } finally {
         setIsFetching(false)
       }
      }
    }
    void callAPI()
  }, [auth, auth.user, token])

  React.useEffect(() => {
    try {
      if (auth.isAuthenticated && !isFetching && !window.LimbicChatbot.initialized) {
        const claimNumber = sessionStorage.getItem("claimNumber") ?? ""
        const json = localStorage.getItem("data")
        if (!json) return

        const data = JSON.parse(json || "{}")
        const {entityId, isEligible, eligibilityProfile} = data

        const customFields = { ...data, ...eligibilityProfile, entityId, isEligible, }
        if (claimNumber) customFields.claimNumber = claimNumber

        window.LimbicChatbot.init!({
          API_KEY,
          customFields,
          overrides: { fullscreen: true }
        })
      }
    } catch (e: any) {
      console.error({ e })
    }
  }, [auth, auth.isAuthenticated, isFetching])

  if (error) return <ErrorLoader text={error} />
  if (!auth.isAuthenticated && !auth.isLoading) return <Redirect to="/" />
  return <Loading />
}
