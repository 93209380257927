import React from "react"
import Logo from "../assets/logo.png"
import "./loading.css"

interface Props {
  text?: string
}

function Loading(props: Props): JSX.Element {
  const { text } = props
  return (
    <div className="loading-container">
      {text && <span className="loading-text">{text}</span>}
      <img className="loading-logo" src={Logo} alt="Limbic"/>
    </div>
  )
}

export default Loading
