import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Callback from "./routes/callback"
import { OpenIDAuthProvider } from "./context/auth"
import SigninRedirect from "./routes/signinRedirect"
import "./App.css"

function App() {
  return (
    <OpenIDAuthProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/callback" component={Callback} />
          <Route path="*" component={SigninRedirect} />
        </Switch>
      </BrowserRouter>
    </OpenIDAuthProvider>
  )
}

export default App
