import { AuthProvider } from "react-oidc-context"
import React from "react"

const BASE_URI = process.env.REACT_APP_NODE_ENV === "production" ?
  "https://vitality.limbic.ai" :
  "https://vitality-staging.limbic.ai"

const oidConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: `${BASE_URI}/callback`,
  post_logout_redirect_uri: BASE_URI,
  response_type: "code",
  scope: "openid sid1234"
  // client_secret: process.env.REACT_APP_CLIENT_SECRET,
}

export function OpenIDAuthProvider(props: any) {
  React.useEffect(() => {
    const claimNumber = sessionStorage.getItem("claimNumber")
    
    if (!claimNumber) {
      const urlParams = new URLSearchParams(window.location.search)
      const params = [...(urlParams as any).keys()].reduce((obj, key) => {
        obj[key] = urlParams.get(key)
        return obj
      }, {})
      sessionStorage.setItem("claimNumber", params.claimNumber || "")
    }
  }, [])

  return <AuthProvider {...oidConfig} {...props} />
}
